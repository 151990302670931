import React from 'react'
import './ServicesMH.css'

import { BiBrain } from 'react-icons/bi';
import { GiBrain } from 'react-icons/gi';
import { FaTheaterMasks } from 'react-icons/fa';
import { CiFaceFrown } from 'react-icons/ci';
import { FaRegSadTear } from 'react-icons/fa';

import Fade from 'react-reveal/Fade';



import { Link } from 'react-router-dom';
import { Link as LinkRoll } from 'react-scroll'


const ServicesMH = () => {
  return (
    <>

    
      <div className='servicesMH'>
            <div className='servicesMH-content'>

                <div className='servicesMH-header'>
                    <h2>OUR SERVICES</h2>
                    <h1>MENTAL HEALTH TREATMENT</h1>
                    <h3>Recovery begins here.</h3>
                </div>


                <div className='servicesMH-flex container'>

                <Fade left>
                    <div className='servicesMH-flexItem'>
                        <div className='servicesMH-icon'><BiBrain/></div>
                        <h1>ANXIETY</h1>
                        <p>Anxiety is a common mental health disorder marked by persistent worry, fear, and stress that disrupt daily life. Our specialized anxiety treatment program offers effective therapy and support to help you reduce symptoms and improve overall well-being.</p>
                    </div>
                </Fade>

                <Fade top>
                    <div className='servicesMH-flexItem'>
                        <div className='servicesMH-icon'><GiBrain/></div>
                        <h1>DEPRESSION</h1>
                        <p>Depression is a common mental health condition characterized by persistent sadness, fatigue, and loss of interest in daily activities. Our expert-led depression treatment program provides evidence-based therapies and personalized care to help you reclaim your mental health.</p>
                    </div>
                </Fade>

                <Fade right>
                    <div className='servicesMH-flexItem'>
                        <div className='servicesMH-icon'><FaTheaterMasks/></div>
                        <h1>BIPOLAR</h1>
                        <p>Bipolar disorder is a mental health condition characterized by intense mood swings, including manic highs and depressive lows, that impact daily functioning. Our comprehensive bipolar disorder treatment program offers evidence-based therapies and personalized support to help manage symptoms and restore balance.</p>
                    </div>
                </Fade>

                <Fade left>
                    <div className='servicesMH-flexItem'>
                        <div className='servicesMH-icon'><CiFaceFrown/></div>
                        <h1>STRESS DISORDER</h1>
                        <p>Stress disorders, such as acute stress disorder and PTSD, are mental health conditions triggered by traumatic events, causing anxiety, flashbacks, and difficulty coping. Our expert-led stress disorder treatment program provides proven therapies and personalized care to help you manage symptoms and restore well-being.</p>
                    </div>
                </Fade>


                <Fade bottom>
                    <div className='servicesMH-flexItem'>
                        <div className='servicesMH-icon'><FaRegSadTear/></div>
                        <h1>PANIC DISORDER</h1>
                        <p>Panic disorder is a mental health condition characterized by frequent panic attacks, overwhelming fear, and physical symptoms like a racing heart, chest pain, and difficulty breathing. Our comprehensive panic disorder treatment program provides proven therapies and personalized care to help you manage symptoms and improve your quality of life.</p>
                    </div>
                </Fade>

                <Fade right>
                    <div className='servicesMH-flexItem'>
                        <div className='servicesMH-icon'><BiBrain/></div>
                        <h1>SCHIZOPHRENIA</h1>
                        <p>Schizophrenia is a severe mental health disorder that affects perception, causing symptoms like hallucinations, delusions, and disorganized thinking. Our expert-led schizophrenia treatment program provides evidence-based therapies and personalized care to help manage symptoms, improve cognitive function, and enhance quality of life.</p>
                    </div>
                </Fade>
                
                </div>


                <div className='servicesMH-btn'>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                    <Link to="/mental-health">
                        <button>VIEW ALL</button>
                    </Link>
                    </LinkRoll>
                </div>

                <div className='servicesMH-btn2'>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                    <Link to="/mental-health">
                        <button>VIEW ALL</button>
                    </Link>
                    </LinkRoll>
                </div>



            </div>
      </div>
    </>
  )
}

export default ServicesMH
