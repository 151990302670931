import React from 'react'
import ContactForm from '../contact/ContactForm'

import './AlcoholSection.css'

const AlcoholSection = () => {
  return (
<>
      <header className="alcohol-section-header container">
        <h1>Understanding Alcohol Addiction</h1>
        <p>
          Alcohol addiction, also known as Alcohol Use Disorder (AUD), is a chronic
          disease characterized by an inability to control or stop drinking despite
          negative consequences. It affects millions of individuals and families
          worldwide, impacting physical health, mental well-being, and personal
          relationships.
        </p>
      </header>

      <section className="alcohol-section-header container">
        <h2>What is Alcohol Addiction?</h2>
        <p>
          Alcohol addiction is more than just excessive drinking. It is a medical
          condition that alters brain chemistry and leads to physical and emotional
          dependency. Signs of alcohol addiction include cravings, withdrawal symptoms,
          and neglect of responsibilities. Without intervention, it can lead to
          long-term health complications such as liver damage, heart disease, and
          neurological issues.
        </p>
      </section>

      <section className="alcohol-section-header container">
        <h2>Signs and Symptoms</h2>
        <ul>
          <li>Inability to limit alcohol consumption</li>
          <li>Experiencing withdrawal symptoms when not drinking</li>
          <li>Neglecting responsibilities at work, school, or home</li>
          <li>Developing a tolerance, needing more alcohol to feel its effects</li>
          <li>Spending significant time drinking or recovering from alcohol's effects</li>
          <li>Continuing to drink despite knowing the harm it causes</li>
        </ul>
      </section>

      <section className="alcohol-section-header container">
        <h2>The Effects of Alcohol Addiction</h2>
        <p>
          Alcohol addiction affects every aspect of life. Physically, it can damage
          vital organs like the liver and heart. Mentally, it can lead to depression,
          anxiety, and memory problems. Socially, it strains relationships, leads to
          job loss, and creates financial difficulties. Early intervention is crucial
          to prevent these severe outcomes.
        </p>
      </section>

      <section className="alcohol-section-header container">
        <h2>Treatment Options for Alcohol Addiction</h2>
        <p>
          Recovery from alcohol addiction is possible with the right support and
          treatment. Options include:
        </p>
        <br />
        <ul>
          <li><strong>Detoxification:</strong> A supervised process to manage withdrawal symptoms.</li>
          <li><strong>Inpatient Rehabilitation:</strong> Intensive therapy in a controlled environment.</li>
          <li><strong>Outpatient Programs:</strong> Flexible treatment options for those with work or family commitments.</li>
          <li><strong>Counseling and Therapy:</strong> Individual or group therapy to address underlying issues.</li>
          <li><strong>Support Groups:</strong> Peer-led groups like Alcoholics Anonymous (AA) for ongoing support.</li>
        </ul>
      </section>

        <ContactForm />

      <footer className="alcohol-section-header container">
        <p>
          Seeking help for alcohol addiction is a sign of strength, not weakness.
          Take the first step toward recovery by reaching out to our compassionate
          team. Recovery is possible, and we are here to guide you every step of the
          way.
        </p>
      </footer>
    </>
  )
}

export default AlcoholSection
