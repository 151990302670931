import React from 'react'
import './ServicesSA.css'
import Fade from 'react-reveal/Fade';
import { BiBeer } from 'react-icons/bi';
import { FaSyringe } from 'react-icons/fa';
import { CiPillsBottle1 } from 'react-icons/ci';
import { TbPills } from 'react-icons/tb';

import { Link } from 'react-router-dom';
import { Link as LinkRoll } from 'react-scroll'

const ServicesSA = () => {
  return (
    <>
      <div className='servicesSA'>
            <div className='servicesSA-content'>
                
                <div className='servicesSA-header'>
                <h1>OUR SERVICES</h1>
                <h2>SUBSTANCE ABUSE TREATMENT</h2>
                <p>At Natural Rest House Treatment Center, clients receive individual therapy alongside a range of additional treatments, including relapse prevention, trauma therapy, mindfulness meditation, transportation, yoga, and more. We offer support that clients need to allow them to gradually go back to treatment.</p>
                </div>

                <div className='servicesSA-flex'>
                
                <Fade left>
                    <div className='servicesSA-flexItem'>
                        <div className='servicesSA-icon'><BiBeer/></div>
                        <h1>ALCOHOL ADDICTION</h1>
                        <p>Alcohol addiction, or alcoholism, is a chronic substance use disorder marked by compulsive drinking, loss of control, and negative impact on physical and mental health. Our comprehensive alcohol addiction treatment program provides evidence-based therapies and individualized care to help you overcome alcohol dependence and achieve long-term recovery.</p>
                    </div>
                </Fade>


                <Fade right>
                    <div className='servicesSA-flexItem'>
                        <div className='servicesSA-icon'><FaSyringe/></div>
                        <h1>HEROIN ADDICTION</h1>
                        <p>Heroin addiction is a chronic substance use disorder marked by intense cravings, physical dependence, and withdrawal symptoms that disrupt daily life. Our comprehensive heroin addiction treatment program provides evidence-based therapies and personalized care to help you overcome heroin dependence and achieve long-term recovery.</p>
                    </div>
                </Fade>

                <Fade left>                    
                    <div className='servicesSA-flexItem'>
                        <div className='servicesSA-icon'><CiPillsBottle1/></div>
                        <h1>XANAX ADDICTION</h1>
                        <p>Xanax addiction, a form of benzodiazepine use disorder, is characterized by the misuse and dependence on Xanax, leading to tolerance, withdrawal symptoms, and emotional distress. Our comprehensive Xanax addiction treatment program offers evidence-based therapies and personalized care to help you overcome dependency and achieve long-term sobriety.</p>
                    </div>
                </Fade>


                <Fade right>                    
                    <div className='servicesSA-flexItem'>
                        <div className='servicesSA-icon'><TbPills/></div>
                        <h1>PRESCRIPTION ADDICTION</h1>
                        <p>Prescription drug addiction is a chronic substance use disorder caused by the misuse of prescription medications, resulting in physical dependence, withdrawal symptoms, and major life disruptions. Our comprehensive prescription addiction treatment program provides evidence-based therapies, personalized care, and support to help you overcome addiction and achieve long-term recovery.</p>
                    </div>
                </Fade>

                </div>


                <div className='servicesSA-btn'>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                    <Link to="/substance-abuse">
                        <button>VIEW ALL</button>
                    </Link>
                    </LinkRoll>
                </div>

                <div className='servicesSA-btn2'>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                    <Link to="/substance-abuse">
                        <button>VIEW ALL</button>
                    </Link>
                    </LinkRoll>
                </div>


            </div>
      </div>
    </>
  )
}

export default ServicesSA
