import React from 'react'
import './Team5.css'
import { Link } from 'react-router-dom';
import { Link as LinkRoll } from 'react-scroll'
import Fade from 'react-reveal/Fade';

import Destany from '../../assets/destany_cota.png'


const Team5 = () => {
    return (
        <>
        <div className='core'>
        <div className='team-section container'>
        <h1>Meet the Heart of Natural Rest House Recovery: Our Dedicated Team</h1>
        <p>Our dedicated team at Natural Rest House Recovery specializes in treating substance abuse addiction and mental health disorders with evidence-based therapies and compassionate care. We focus on helping individuals overcome challenges, achieve lasting recovery, and regain control of their lives.</p>
            <div>
                <div className="content">

                    <div className='flex-right'>
                    <Fade top>
                    <div className='thumbnail'>
                        <img src="https://rodeo-recovery.s3.us-west-1.amazonaws.com/rodeo_recovery_medias/nicole.jpg" alt="Nicole Scimone Shaver - Operations Director" loading="lazy"/>
                        <div>                   
                        <p><b>Nicole Shaver</b><br/><i>Operations Director</i></p>
                        </div>
                    </div>
                    </Fade>

                    <Fade bottom>
                    <div className='thumbnail'>
                    <img src="https://rodeo-recovery.s3.us-west-1.amazonaws.com/rodeo_recovery_medias/floricinda.jpg" alt="Florincinda Zapata - Lead Case Manager" loading="lazy"/>
                    <p><b>Floricinda Zapata</b><br/><i>Lead Case Manager</i></p>
                    </div>
                    </Fade>

                    <Fade bottom>
                    <div className='thumbnail'>
                    <img src="https://rodeo-recovery.s3.us-west-1.amazonaws.com/rodeo_recovery_medias/michael.jpg" alt="Michael Marquez - Behavioral Health Technician" loading="lazy"/>
                    <p><b>Michael Marquez</b><br/><i>Behavioral Health Technician</i></p>
                    </div>
                    </Fade>
        
                    <Fade bottom>
                    <div className='thumbnail'>
                    <img src={Destany} alt="Destany Cota - Behavioral Health Technician" loading="lazy"/>
                    <p><b>Destany Cota</b><br/><i>LVN Lead</i></p>
                    </div>
                    </Fade>  

                    </div>

                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                    <Link to="/team">
                        <button>Learn More</button>
                    </Link>
                    </LinkRoll>



                </div>
            </div>
        </div>
        </div>

</>
    )
}

export default Team5
